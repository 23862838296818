import React, { useContext, useEffect, useState } from 'react';

import {
  api,
  deleteCookieOnAllDomains,
  IBLSpinLoader,
  isJSON,
} from '@iblai/ibl-web-react-common';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import {
  getParentDomain,
  handleLogout,
  setCommonCookie,
} from '../../utils/utils';
import StaticHeader from '../../components/StaticHeader/StaticHeader';
import { AuthContext } from '../../utils/context';
import { defaultSPALogoData } from '../../helpers';
import Footer from '../../components/Footer/Footer';
import useNotification from '../../hooks/useNotification';
import Notifications from '../../components/notifications';
import FreeTrial from '../../components/FreeTrial/FreeTrial';
import { SubmitButton } from '../../components/SubmitButton';
import MfeContextUI from '../../components/MfeContextUI/MfeContextUI';
import { LoginPasswordInputSection } from './LoginPasswordInputSection';
import { LoginEmailUsernameInputSection } from './LoginEmailUsernameInputSection';

import '../../assets/css/custom.css';
import '../../assets/css/webflow.css';
import '../../assets/css/normalize.css';
import '../../assets/css/ibl-default-login.webflow.css';

const LOGO_DATA = defaultSPALogoData;

const ERROR_MESSAGES = {
  FIELD_REQUIRED: 'This field is required',
};

const Login = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [enforceLogin] = useState(queryParams.get('enforce-login'));
  const [logout] = useState(queryParams.get('logout'));
  const [redirectTo] = useState(
    queryParams.get('redirect-to')
      ? queryParams.get('redirect-to')
      : localStorage.getItem('redirectTo')
  );
  const [redirectToken] = useState(queryParams.get('redirect-token'));
  const [redirectPath] = useState(queryParams.get('redirect-path'));
  const authData = useContext(AuthContext);
  const [notifications, notify, onClose] = useNotification();
  const { t } = useTranslation();
  const customAuthBackend = queryParams.get('custom_sso_backend_path');
  const externalProviderId = queryParams.get('idp_provider');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showPasswordScreen, setShowPasswordScreen] = useState(false);

  const [{ emailOrUsername, password }, setFormData] = useState({
    emailOrUsername: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    emailOrUsername: '',
    password: '',
  });

  function handleSetErrors(key, value) {
    setErrors((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  useEffect(() => {
    if (logout || api.iblutils.isTokenExpired()) {
      handleLogout();
    }
    const returningCustomerData = localStorage.getItem('returningCustomerData');
    const selectedTenant = queryParams.get('tenant')
      ? queryParams.get('tenant')
      : '';
    if (customAuthBackend) {
      handleLogout();
      const redirectUri = encodeURI(`${window.location.origin}/login/complete`);
      const nextUrl = `/oauth2/authorize?response_type=code&client_id=${
        process.env.REACT_APP_IBL_EDX_SSO_CLIENT_ID
      }&scope=profile%20email&redirect_uri=${redirectUri}${
        externalProviderId ? '&idp=' + externalProviderId : ''
      }`;
      window.location.href = `${
        process.env.REACT_APP_IBL_LMS_URL
      }${customAuthBackend}?auth_entry=login&next=${encodeURIComponent(
        nextUrl
      )}`;
    } else if (process.env.REACT_APP_IBL_DIRECT_SSO_URL) {
      api.ibledxplatform.getCsrfToken(1440, () => {
        window.location.href = process.env.REACT_APP_IBL_DIRECT_SSO_URL;
      });
    } else {
      api.ibledxplatform.getCsrfToken(1440);
      if (
        authData.axd_token &&
        authData.dm_token &&
        !enforceLogin &&
        !logout &&
        !api.iblutils.isTokenExpired()
      ) {
        const {
          axd_token,
          axd_token_expires,
          userData,
          tenants,
          dm_token_expires,
          tenant,
          selected_tenant,
          dm_token,
          current_tenant,
        } = { ...localStorage };

        const userObject = {
          axd_token,
          axd_token_expires,
          userData,
          dm_token_expires,
          tenant,
          dm_token,
        };
        setCommonCookie('userData', localStorage.userData);
        setCommonCookie('currentTenant', localStorage.current_tenant);
        if (redirectToken) {
          const seperator = redirectPath.includes('?') ? '&' : '?';
          api.ibldmcore.getRedirectUrlFromToken(
            { org: tenant, redirectToken },
            (data) => {
              userObject['tenants'] = JSON.stringify(
                JSON.parse(tenants).map((tenant) => ({
                  key: tenant.key,
                  name: tenant.name,
                  is_admin: tenant.is_admin,
                  username: tenant.username,
                }))
              );
              window.location.href = `${
                data['url']
              }${redirectPath}${seperator}ibl-data=${encodeURIComponent(
                JSON.stringify(userObject)
              )}`;
              localStorage.removeItem('redirectToken');
              localStorage.removeItem('redirectPath');
            },
            (error) => {
              console.error(
                'Error exchanging redirect token for a redirect url ',
                error,
                '. Redirect token : ',
                redirectToken
              );
              window.location.href = '/';
            }
          );
        } else if (redirectTo) {
          deleteCookieOnAllDomains('userData', window.location.hostname);
          document.cookie = `userData=${encodeURIComponent(
            localStorage.userData
          )}; domain=${getParentDomain(
            window.location.hostname
          )}; path=/; secure; sameSite=none;`;
          localStorage.removeItem('returningCustomerData');
          if (isJSON(returningCustomerData)) {
            const queryParam = new URLSearchParams(
              JSON.parse(returningCustomerData)
            ).toString();
            window.location.href = `${redirectTo}?${queryParam}`;
          } else {
            console.log('redirecting the user with user object ', userObject);
            window.location.href = `${redirectTo}/sso-login?data=${encodeURIComponent(
              JSON.stringify(userObject)
            )} `;
          }
        }
      } else {
        handleLogout();
        if (returningCustomerData && isJSON(returningCustomerData)) {
          localStorage.setItem('returningCustomerData', returningCustomerData);
        }
      }
    }
    if (redirectTo) {
      localStorage.setItem('redirectTo', redirectTo);
    }
    localStorage.setItem('selected_tenant', selectedTenant);
    if (redirectToken) {
      localStorage.setItem('redirectToken', redirectToken);
    } else {
      localStorage.removeItem('isExternal');
    }
    if (redirectPath) {
      localStorage.setItem('redirectPath', redirectPath);
    } else {
      localStorage.removeItem('redirectPath');
    }
  }, []);

  function handleInputChange(inputEvent) {
    const { name, value } = inputEvent.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    handleSetErrors(name, '');
  }

  const handleResetForm = () => {
    setFormData({ emailOrUsername: '', password: '' });
    setErrors({ emailOrUsername: '', password: '' });
    setIsSubmitting(false);
    setShowPasswordScreen(false);
  };

  const handleLoginSubmit = ({
    username,
    password,
    handleResetForm,
    notify,
    retry = true,
  }) => {
    const formData = new FormData();
    formData.append('email_or_username', username);
    formData.append('password', password);

    api.iblwebauth.login(
      formData,
      (data) => {
        window.location.href = '/login/complete';
      },
      (error) => {
        handleResetForm();
        if (error?.status === 403 && retry) {
          api.ibledxplatform.getCsrfToken(1440, () => {
            handleLoginSubmit({
              username,
              password,
              handleResetForm,
              notify,
              retry: false,
            });
          });
        } else {
          notify('Credentials incorrect', error.responseJSON?.value, 'error');
        }
      }
    );
  };

  const formSubmit = (formEvent) => {
    formEvent.preventDefault();

    if (!showPasswordScreen) {
      // validate email or username
      if (!emailOrUsername || emailOrUsername.length === 0) {
        handleSetErrors('emailOrUsername', ERROR_MESSAGES.FIELD_REQUIRED);
        return;
      }

      setShowPasswordScreen(true);
      return;
    }

    // validate password
    if (!password || password.length === 0) {
      handleSetErrors('password', ERROR_MESSAGES.FIELD_REQUIRED);
      return;
    }

    setIsSubmitting(true);

    handleLoginSubmit({
      username: String(emailOrUsername).trim(),
      password: String(password).trim(),
      handleResetForm,
      notify,
    });

    return;
  };
  const STATIC_HEADER_ENABLED =
    process.env.REACT_APP_IBL_STATIC_HEADER_ENABLED === 'true';
  return (
    <>
      {(!authData.axd_token || enforceLogin || logout) &&
      !process.env.REACT_APP_IBL_DIRECT_SSO_URL &&
      !customAuthBackend ? (
        <div className="w-layout-vflex auth-page-container">
          {STATIC_HEADER_ENABLED && <StaticHeader />}
          <FreeTrial />
          <div className="w-layout-vflex auth-page-block">
            {!STATIC_HEADER_ENABLED && (
              <img
                src={LOGO_DATA.logo}
                loading="lazy"
                alt=""
                className="auth-logo-img"
              />
            )}
            <div className="w-layout-vflex auth-page-main-content">
              <h2 className="auth-page-title">{t('Welcome Back')}</h2>
              <div className="auth-form-block w-form">
                <form className="auth-form" onSubmit={formSubmit}>
                  {showPasswordScreen ? (
                    <LoginPasswordInputSection
                      errorMessage={errors.password}
                      password={password}
                      handleInputChange={handleInputChange}
                      redirectTo={redirectTo}
                    />
                  ) : (
                    <LoginEmailUsernameInputSection
                      emailOrUsername={emailOrUsername}
                      errorMessage={errors.emailOrUsername}
                      handleInputChange={handleInputChange}
                    />
                  )}
                  <SubmitButton isSubmitting={isSubmitting} />
                </form>
                <div className="w-form-done">
                  <div>
                    {t('Thank you! Your submission has been received!')}
                  </div>
                </div>
                <div className="w-form-fail">
                  <div>
                    {t('Oops! Something went wrong while submitting the form.')}
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex auth-redirect-block">
                <div className="text-block-29">
                  {t("Don't have an account")}?
                </div>
                <Link
                  to={`/signup?redirect-to=${redirectTo}`}
                  className="auth-link-redirect"
                >
                  {t('Sign Up')}
                </Link>
              </div>
              <MfeContextUI />
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {process.env.REACT_APP_IBL_DIRECT_SSO_URL && <IBLSpinLoader />}
        </div>
      )}
      {Object.entries(notifications).map(([key, notificationObj]) => (
        <Notifications
          onClose={() => onClose(key)}
          key={key}
          displayMsg={notificationObj.displayMsg}
          headerMsg={notificationObj.headerMsg}
          type={notificationObj.type}
        />
      ))}
    </>
  );
};

export default Login;
