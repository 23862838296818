import { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import eye from '../../assets/images/eye-1.svg';
import eyeOff from '../../assets/images/eye-off.svg';

export function LoginPasswordInputSection({
  errorMessage,
  password,
  handleInputChange,
  redirectTo,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputRef = useRef(null);

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, [passwordInputRef]);

  return (
    <div className="w-layout-vflex auth-input-group visible">
      <input
        ref={passwordInputRef}
        className="auth-input password-field password w-input"
        maxLength="256"
        name="password"
        placeholder={t('Password')}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={handleInputChange}
      />
      {errorMessage.length !== 0 && (
        <div
          className="text-error hidden"
          role="alert"
          aria-label="Minimum 8 characters"
        >
          {t('Minimum 8 characters')}
        </div>
      )}
      <Link
        to={`/forgot-password?redirect-to=${redirectTo}`}
        className="auth-link-redirect mt-small"
      >
        {t('Forgot password?')}
      </Link>
      <div
        className="w-layout-hflex password-eye-block"
        onClick={() => setShowPassword((prevState) => !prevState)}
      >
        <img
          src={!showPassword ? eyeOff : eye}
          loading="lazy"
          alt=""
          className="password-eye-icon"
        />
      </div>
    </div>
  );
}
